import React, { Component } from "react";
import * as ReactDOM from "react-dom";
import styled from "styled-components";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import CopyrightIcon from "@material-ui/icons/Copyright";
// const drawerWidth = 440;
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import RefreshIcon from "@material-ui/icons/Refresh";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useCookies } from 'react-cookie'
import { confirmAlert } from 'react-confirm-alert' // Import

import {
  ConvertDate,
  ConverMoeny,
  ConvertBalanceStateToText,
} from "../../utility/help";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

import { normalizeUnits } from "moment";
import { UserService } from "../../service/user.service";
import { SlotService } from "../../service/slot.service";
import { BalanceService } from "../../service/balance.service";


import { Deposit } from "./deposit";
import { Withdraw } from "./withdraw";
import { Help } from "./help";
import { User } from "./user";
import { Notice } from "./notice";
import { Reg } from "./reg";
import { Login } from "./login";
import { Even } from "./even";
import { Coupon } from "./coupon";
import { Edit } from "./edit";
import { Point } from "./point";
import { Note } from "./note";
import { BalanceList } from "./balancelist";
import { Bet } from "./bet";
import { Rolling } from "./rolling";

import SoundPlays from "../../SoundPlay";




export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  notice = "notice",
  even = "even",
  
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
  coupon = "coupon",
  edit= "edit",
  point= "point",
  balance= "balance",
  bet = "bet",
  rolling ="rolling" 
}
const CustomButton = styled(Button)`
  /* background-color: #6772e5; */
  /* color: #000;
  line-height: normal;
  font-size: 12px;
  font: bold; */

  box-shadow: inset 0px 1px 0px 0px #cf866c;
  background: linear-gradient(to bottom, #d0451b 5%, #bc3315 100%);
  background-color: #d0451b;
  border-radius: 3px;
  border: 1px solid #942911;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 16px;
  /* padding: 6px 24px; */
  text-decoration: none;
  width: 20%;
  text-shadow: 0px 1px 0px #854629;
`;

const styles = (theme: any) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    color: "#000",
  },

  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    alignSelf: "flex-end",
    display: "inline",
    padding: "10px",
    fontSize: "16px",
  },
  investing: {
    fontSize: "18px",
  },
});

const CustomToolbar = styled(Toolbar)`
  /* .muitoolbar-regular : {
  } */
`;

interface Props {
  classes: any;
  user: any;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
}

interface State {
  ID: string;
  PW: string;

  balance: number;
  messageCount: number;

  postCount: number;

  note: any;

  popupStatuses: string;
  mobileMenuOpen: boolean;

  width : number

  openSide : boolean

}


class topBar extends Component<Props, State> {
  static propTypes: { classes: PropTypes.Validator<object> };
  userService = new UserService();
  slotService = new SlotService();
  balanceService = new BalanceService();

  constructor(props: Props) {
    super(props);
    this.state = {
      balance: 0,
      ID: "",
      PW: "",
      postCount: 0,
      messageCount: 0,
      
      note: [],
      popupStatuses: popupView.none,
      mobileMenuOpen : false,
      width : window.innerWidth,
      openSide : false
    };
  }

  componentDidMount() {
    setInterval(() => {
      this.handleUpdateNote();
    }, 30000);

    // setInterval(() => {
    //   this.handleUpdateMessage();
    // }, 10000);
    this.handleUpdateNote();
    this.handleUpdateInBalance();


    // this.handleUpdateMessage();
    
  }

  handleUpdateInBalance = () => {


    this.slotService.get_in_game_balance().then((data: any) => {
      if (data.status === "success") {
        this.setState({
          balance: data.balance ?? 0,
        });
      } else {
      }
    });
  };

  
  // handleUpdateMessage = () => {
  //   // if(this.state.messageCount > 0){
  //   //   return 
  //   // }

  //   this.slotService.get_help_no_read_message().then((data: any) => {
  //     if (data.status === "success") {
  //       this.setState({
  //         messageCount: data.count ?? 0,
  //       });


  //       if( data.count > 0) {
  //         SoundPlays('helpCount')
  //       }
  //     } else {
  //     }
  //   });
  // };

  handleUpdateNote = () => {
    if(this.state.popupStatuses === popupView.note){
      return 
    }
    this.userService.get_user_note().then((data: any) => {
      if (data.status === "success") {
        // this.setState({
        //   postCount: data.count,
        //   note: data.note,
        //   isOpen: data.count > 0 ? true : false,
        // });
        if(data.count > 0){
          this.handleSetState(popupView.note)
        }

      } else if (this.props.authenticated) {
        this.props.tryLoginOut();
      }
    });
  };

  handleSetState = (state: string)=>{
    this.setState({popupStatuses: state})
  }

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  

  handleAskToAccount = () => {
  
    this.balanceService.askToAccount().then((data) => {
      if (data.status === "success") {
        confirmAlert({
          title: "계좌문의",
          message: "계좌문의을 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },
          ],
        });
        return;
      } else if (data.status === "pass") {
        confirmAlert({
          title: "계좌문의",
          message: "환전 비밀번호를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "계좌문의",
          message:
            "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };

  render() {
    const classes = this.props.classes;
    const user = this.props.user;
    const authenticated = this.props.authenticated;

    if(this.state.popupStatuses === popupView.none && user.activeHelp === true){
      this.setState({popupStatuses : popupView.help})
    }
    
    const RenderPopup = () => {
      
      if (this.state.popupStatuses === popupView.none) {
        return <></>
      }
      

      if (this.state.popupStatuses === popupView.reg) {
        return <Reg handleClose={this.handleClosePopup}></Reg>;
      }
      
      if (this.state.popupStatuses === popupView.login) {
        return <Login handleClose={this.handleClosePopup} tryLogin={this.props.tryLogin}></Login>;
      }


      if((user == null || user.id == '' || user.id == null)  && this.state.popupStatuses != popupView.none){
        confirmAlert({
          title: '로그인 이후 사용가능합니다.',
          buttons: [
            {
              label: '확인',
              onClick: () => {

                this.setState({popupStatuses : popupView.none})

               },
            },
          ],
        })
        return
      }


      if (this.state.popupStatuses === popupView.deposit) {
        return <Deposit 
          handleClose={this.handleClosePopup} 
          user={this.props.user}
          handleActive={this.handleSetState}
        > </Deposit>;
      }

      if (this.state.popupStatuses === popupView.withdraw) {
        return <Withdraw
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleActive={this.handleSetState}></Withdraw>;
      }
      if (this.state.popupStatuses === popupView.notice) {
        return <Notice 
          handleClose={this.handleClosePopup}
          handleActive={this.handleSetState}        ></Notice>
      }


      
      if (this.state.popupStatuses === popupView.coupon) {
        return <Coupon 
          handleClose={this.handleClosePopup} 
          handleActive={this.handleSetState}        ></Coupon>;
      }
      
      

      if (this.state.popupStatuses === popupView.even) {
        return <Even 
          handleClose={this.handleClosePopup} 
          handleActive={this.handleSetState}        ></Even>;
      }
      

      if (this.state.popupStatuses === popupView.help) {
        return <Help 
          handleClose={this.handleClosePopup} 
          handleActive={this.handleSetState}        ></Help>;
      }
      if (this.state.popupStatuses === popupView.user) {
        return <User 
        user={this.props.user}
          handleClose={this.handleClosePopup} 
          handleActive={this.handleSetState}        ></User>;
      }

      
      
      if (this.state.popupStatuses === popupView.rolling) {
        return <Rolling   
        user={this.props.user}
        handleClose={this.handleClosePopup} ></Rolling>;
      }
      
      if (this.state.popupStatuses === popupView.edit) {
        return <Edit 
        handleClose={this.handleClosePopup} 
        handleActive={this.handleSetState}   
        user={this.props.user}    ></Edit>;
      }

      if (this.state.popupStatuses === popupView.point) {
        return <Point 
        user={this.props.user}
          handleClose={this.handleClosePopup}       ></Point>;
      }
      
      
      if (this.state.popupStatuses === popupView.note) {
        return <Note   
        handleClose={this.handleClosePopup} 
        handleActive={this.handleSetState}     ></Note>;
      }
      

         
      if (this.state.popupStatuses === popupView.balance) {
        return <BalanceList   
        handleClose={this.handleClosePopup} 
        handleActive={this.handleSetState}     ></BalanceList>;
      }
      
      if (this.state.popupStatuses === popupView.bet) {
        return <Bet   
        handleClose={this.handleClosePopup} 
        handleActive={this.handleSetState}     ></Bet>;
      }
      
      return <div></div>;
    };


    return (
      <>
        	<div className="container">			
        <div className="web-navigation">
        
          <div className="header-logo w-50" >
            <img src="/coin/assets/images/icon/logo.png" style={{width: '150px'}} />
          </div>
          
          {
              this.props.authenticated ? (
                <div className="show-login text-white" >
                  <label className="mx-1">아이디 : {user.id}</label>
                  <label className="mx-1">통합머니 :  {ConverMoeny(this.state.balance)}원</label>
                  <button className="btn btn-danger logout-btn" onClick={()=> this.props.tryLoginOut()}>로그아웃</button>
                </div>
                  ) : (
                    <div className="credentials" >
                      <div className="grp">
                        <img src="/coin/assets/images/icon/refresh.svg" />
                        <input type="text" name="" placeholder="아이디" value={this.state.ID} onChange={(e: any) => { this.setState({ ID: e.target.value });}} />
                      </div>
                      <div className="grp">
                        <img src="/coin/assets/images/icon/refresh.svg" />
                        <input type="password" name="" placeholder="비밀번호" value={this.state.PW} onChange={(e: any) => { this.setState({ PW: e.target.value }); }} />
                      </div>
          
                      <button className="login-btn" style={{whiteSpace: 'nowrap'}} onClick={()=>{ this.props.tryLogin(this.state.ID, this.state.PW)}}>로그인</button>
                      <button className="register-btn" style={{whiteSpace: 'nowrap'}}  onClick={() => { this.setState({ popupStatuses: popupView.reg });}}>회원가입</button>
                    </div>
        
                  )
          }
        </div>

      </div>
      <div className="second-navigation py-2">
        <div className="container">
          <ul>
            <li data-toggle="modal" data-target="#WithdrawalAndDepositModal" onClick={() => { this.setState({popupStatuses: popupView.deposit,});}} >입금신청</li>
            <li data-toggle="modal" data-target="#WithdrawalAndDepositModal" onClick={() => { this.setState({popupStatuses: popupView.withdraw,});}} >출금신청</li>
            <li data-toggle="modal" data-target="#WithdrawalAndDepositModal" onClick={() => { this.setState({popupStatuses: popupView.rolling,});}}>콤프</li>
            <li data-toggle="modal" data-target="#NoticeModal" onClick={() => { this.setState({popupStatuses: popupView.notice,});}}>공지사항</li>
            <li data-toggle="modal" data-target="#ServiceModal" onClick={() => { this.setState({popupStatuses: popupView.help,});}}>고객센터</li>
            <li  data-toggle="modal" data-target="#MypageModal" onClick={() => { this.setState({popupStatuses: popupView.edit,});}}>마이페이지</li>
            
          </ul>
        </div>
      </div>
      <div className="second-navigation py-2 fixed-position">
        <div className="container">
          <ul>
            <li style={{display: 'flex',alignItems: 'center'}}><img src="/coin/assets/images/icon/logo.png" style={{width: '100px'}} /></li>
            <li data-toggle="modal" data-target="#WithdrawalAndDepositModal" onClick={() => { this.setState({popupStatuses: popupView.deposit,});}} >입금신청</li>
            <li data-toggle="modal" data-target="#WithdrawalAndDepositModal" onClick={() => { this.setState({popupStatuses: popupView.withdraw,});}} >출금신청</li>
            <li data-toggle="modal" data-target="#NoticeModal" onClick={() => { this.setState({popupStatuses: popupView.rolling,});}}>콤프</li>
            <li data-toggle="modal" data-target="#NoticeModal" onClick={() => { this.setState({popupStatuses: popupView.notice,});}}>공지사항</li>
            <li data-toggle="modal" data-target="#ServiceModal" onClick={() => { this.setState({popupStatuses: popupView.help,});}}>고객센터</li>
            <li  data-toggle="modal" data-target="#MypageModal" onClick={() => { this.setState({popupStatuses: popupView.edit,});}}>마이페이지</li>
          </ul>
          <div style={{marginTop: '-45px'}}>
              
          {
              this.props.authenticated ? (
                <div className="show-login text-white" style={{ float: 'right'}}>
                <label className="mx-1">아이디 : {user.id}</label>
                <label className="mx-1">통합머니 : {ConverMoeny(this.state.balance)}원</label>
                {/* <label className="mx-1">쿠폰 : <button className="btn btn-dark" style={{borderBottom: '2px solid #ffbc00'}}>0개</button></label> */}
                <button className="btn btn-danger logout-btn" onClick={()=> this.props.tryLoginOut()}> 로그아웃</button>
              </div>
      
               ) : (
                <div className="credentials" >
                  <div className="grp">
                    <img src="/coin/assets/images/icon/refresh.svg" />
                    <input type="text" name="" placeholder="아이디" value={this.state.ID} onChange={(e: any) => { this.setState({ ID: e.target.value });}}  />
                  </div>
                  <div className="grp">
                    <img src="/coin/assets/images/icon/refresh.svg" />
                    <input type="password" name="" placeholder="비밀번호"  value={this.state.PW} onChange={(e: any) => { this.setState({ PW: e.target.value }); }} />
                  </div>
        
                  <button className="login-btn"  style={{whiteSpace: 'nowrap'}} onClick={()=>{ this.props.tryLogin(this.state.ID, this.state.PW)}}>로그인</button>
                  <button className="register-btn"  style={{whiteSpace: 'nowrap'}}  onClick={() => { this.setState({ popupStatuses: popupView.reg });}}>회원가입</button>
                </div>
               )
          }
         
          </div>
        </div>
      </div>

      <div className="mobile-navigation">
        {/* <button className="nav-left"><img src="/coin/assets/images/icon/nav.svg" /></button> */}
        <button className="nav-right" onClick={()=> this.setState({openSide : true})}><img src="/coin/assets/images/icon/user.svg" /></button>
        <img src="/coin/assets/images/icon/logo.png" style={{width:'150px'}} />
      </div>

      <div className="holder" style={{backgroundColor: 'rgba(18,18,18,.7)', overflow: 'hidden'}}>
        <div className="container py-3" style={{position: 'relative'}}>
          <div className="owl-carousel owl-theme carou-style" id="carou-style">
              <div className="item"><img src="/coin/assets/images/carousel/1.webp" /></div>
              {/* <div className="item"><img src="/coin/assets/images/carousel/2.webp" /></div>
              <div className="item"><img src="/coin/assets/images/carousel/3.webp" /></div>
              <div className="item"><img src="/coin/assets/images/carousel/4.webp" /></div>
              <div className="item"><img src="/coin/assets/images/carousel/5.webp" /></div>
              <div className="item"><img src="/coin/assets/images/carousel/6.webp" /></div>
              <div className="item"><img src="/coin/assets/images/carousel/7.webp" /></div>
              <div className="item"><img src="/coin/assets/images/carousel/8.webp" /></div>
              <div className="item"><img src="/coin/assets/images/carousel/9.webp" /></div>
              <div className="item"><img src="/coin/assets/images/carousel/10.webp" /></div> */}
              <div className="item"><img src="/coin/assets/images/carousel/11.webp" /></div>
          </div>
        </div>
      </div>
        
        
	<div className="sidebar">
		<button className="float-right mb-1 _close_side_navigation" onClick={()=> this.setState({openSide : false})}><i className="fas fa-times"></i></button>
		<div className="clearfix"></div>
		<div className="_sidebar_hotgames">
			<img src="/coin/assets/images/icon/fire.png" className="_hot_fire" />
			<img src="/coin/assets/images/icon/hotgames.png" className="w-100" />
		</div>


          <div className="text-center mt-4">	
              <h3 style={{color: '#fff'}}>Slot</h3>
            </div>
            <div className="_sidebar_menu my-2"  onClick={()=>{ 
              window.location.hash = '/game/pragmaticplay' 
             }}>
              <img src="/light/images/icon/casino/pragmatic.png"/>
              <label className="_casino_name">프라그매틱플레이</label>
            </div>
            <div className="_sidebar_menu my-2"  onClick={()=>{ window.location.hash = '/game/cq9' 
            window.location.reload() }}>
              <img src="/light/images/icon/casino/cq9.png"/>
              <label className="_casino_name">CQ9</label>
            </div>
            <div className="_sidebar_menu my-2"  onClick={()=>{ window.location.hash = '/game/habanero' 
          window.location.reload()}}>
              <img src="/light/images/icon/casino/habanero.png"/>

              <label className="_casino_name">하바네로</label>
            </div>
            <div className="_sidebar_menu my-2"  onClick={()=>{ window.location.hash = '/game/playngo'
          window.location.reload() }}>
              <img src="/light/images/icon/casino/playngo.png"/>
              <label className="_casino_name">플레이엔고</label>
            </div>
            <div className="_sidebar_menu my-2"  onClick={()=>{ window.location.hash = '/game/playstar' 
          window.location.reload()}}>
              <img src="/light/images/icon/casino/playstar.png"/>
              <label className="_casino_name">플레이스타</label>
            </div>
            <div className="_sidebar_menu my-2"  onClick={()=>{ window.location.hash = '/game/booongo'
          window.location.reload()}}>
              <img src="/light/images/icon/casino/bng.png" />
              <label className="_casino_name">비엔지</label>
            </div>
            <div className="_sidebar_menu my-2"  onClick={()=>{ window.location.hash = '/game/isoftbet' 
          window.location.reload()}}>
              <img src="/light/images/icon/casino/isoftbet.png" />
              <label className="_casino_name">아이소프트벳</label>
            </div>
            <div className="_sidebar_menu my-2"  onClick={()=>{ window.location.hash = '/game/playson' 
          window.location.reload()}}>
              <img src="/light/images/icon/casino/playson.png" />
              <label className="_casino_name">플레이슨</label>
            </div>
            <div className="_sidebar_menu my-2"  onClick={()=>{ window.location.hash = '/game/gameart' 
          window.location.reload()}}>
              <img src="/light/images/icon/casino/gameart.png" />
              <label className="_casino_name">게임아트</label>
            </div>
            <div className="_sidebar_menu my-2"  onClick={()=>{ window.location.hash = '/game/genesis'
          window.location.reload()}}>
              <img src="/light/images/icon/casino/genesis.png" />
              <label className="_casino_name">제네시스</label>
            </div>
            <div className="_sidebar_menu my-2"  onClick={()=>{ window.location.hash = '/game/dreamtech'
          window.location.reload()}}>
              <img src="/light/images/icon/casino/dreamtech.png" />
              <label className="_casino_name">드림테크</label>
            </div>
            <div className="_sidebar_menu my-2"  onClick={()=>{ window.location.hash = '/game/asiagame'
          window.location.reload()}}>
              <img src="/light/images/icon/casino/ag.png" />
              <label className="_casino_name">아시아게이밍</label>
            </div>
            <div className="_sidebar_menu my-2"  onClick={()=>{ window.location.hash = '/game/betsoft'
          window.location.reload()}}>
              <img src="/light/images/icon/casino/betsoft.png" />
              <label className="_casino_name">벳소프트</label>
            </div>
            <div className="_sidebar_menu my-2"  onClick={()=>{ window.location.hash = '/game/evoplay'
          window.location.reload()}}>
              <img src="/light/images/icon/casino/evoplay.png" />
              <label className="_casino_name">에보플레이</label>
            </div>
            <div className="_sidebar_menu my-2"  onClick={()=>{ window.location.hash = '/game/bgames'
          window.location.reload()}}>
              <img src="/light/images/icon/casino/bgaming.png" />
              <label className="_casino_name">BGaming</label>
            </div>
          </div>
		<div className="_user_sidebar" style={{'right' :  this.state.openSide ? '0px'  : '100%'}}>
				<button className="float-right mb-1 _close_side_navigation" onClick={()=> this.setState({openSide : false})}><i className="fas fa-times"></i></button>
				<div className="clearfix"></div>
     

        {
               this.props.authenticated ? (
                <div className="_user_info _user_show" style={{paddingTop: '40px'}}>
                  <img className="img-fluid _login_icon" src="/coin/assets/images/icon/pre-login-reg.png" />
                  <table className="w-100">
                    <tbody><tr>
                      <td>아이디 :</td>
                      <td> {user.id}</td>
                    </tr>
                    <tr>
                      <td>통합머니 :</td>
                      <td>{ConverMoeny(this.state.balance)}원</td>
                    </tr>
                  
                  </tbody>
                  </table>
                  <div className="text-center mt-4">
                    <button className="_modal_bottom_button_type_1 logout-btn" style={{borderRadius: '10px'}}  onClick={()=> this.props.tryLoginOut()}>로그아웃</button>
                  </div>
                </div>
               ):(
                <div className="_login_form p-2 text-center  _guest_show" style={{marginBottom: '50px'}}>
                  <div className="_custom_group_inputs">	
                    <input type="text" name="" placeholder="아이디" style={{width: '100%'}}  value={this.state.ID} onChange={(e: any) => { this.setState({ ID: e.target.value });}}  />
                  </div>
                  <div className="_custom_group_inputs">
                    
                    <input type="password" name="" placeholder="비밀번호" style={{width: '100%'}}  value={this.state.PW} onChange={(e: any) => { this.setState({ PW: e.target.value }); }}  />
                  </div>
                  <div className="text-center mt-2 _bal_container " style={{top: '150px', right: '-50%' ,width: '100%' }}>
                    <button className="w-100 btn btn-warning text-dark mb-1 login-btn" style={{height: 'auto'}} onClick={()=>{ this.props.tryLogin(this.state.ID, this.state.PW)}}> 로그인</button>
                    <button className="w-100 btn btn-success mb-1" data-toggle="modal" data-target="#_Register_Modal"  onClick={() => { this.setState({ popupStatuses: popupView.reg });}}>회원가입</button>
                  </div>
                </div>
               )
               }
				<div className="_user_menu">
					<div className="_inner_user_menu" data-toggle="modal" data-target="#WithdrawalAndDepositModal" onClick={() => { this.setState({popupStatuses: popupView.deposit,});}}>
						입금신청
					</div>
          <div className="_inner_user_menu" data-toggle="modal" data-target="#WithdrawalAndDepositModal"  onClick={() => { this.setState({popupStatuses: popupView.withdraw,});}} >
						출금신청
					</div>
          <div className="_inner_user_menu" data-toggle="modal" data-target="#WithdrawalAndDepositModal"  onClick={() => { this.setState({popupStatuses: popupView.rolling,});}} >
						콤프
					</div>
					<div className="_inner_user_menu" data-toggle="modal" data-target="#NoticeModal"  onClick={() => { this.setState({popupStatuses: popupView.notice,});}}>
						공지사항
					</div>
					<div className="_inner_user_menu" data-toggle="modal" data-target="#ServiceModal" onClick={() => { this.setState({popupStatuses: popupView.help,});}}>
						고객센터
					</div>
					<div className="_inner_user_menu" data-toggle="modal" data-target="#MypageModal" onClick={() => { this.setState({popupStatuses: popupView.edit,});}}>
						마이페이지
					</div>

{/* 
          <li style={{display: 'flex',alignItems: 'center'}}><img src="/coin/assets/images/icon/logo.png" style={{width: '100px'}} /></li>
            <li data-toggle="modal" data-target="#WithdrawalAndDepositModal" onClick={() => { this.setState({popupStatuses: popupView.deposit,});}} >입금신청</li>
            <li data-toggle="modal" data-target="#WithdrawalAndDepositModal" onClick={() => { this.setState({popupStatuses: popupView.withdraw,});}} >출금신청</li>
            <li data-toggle="modal" data-target="#NoticeModal" onClick={() => { this.setState({popupStatuses: popupView.notice,});}}>공지사항</li>
            <li data-toggle="modal" data-target="#ServiceModal" onClick={() => { this.setState({popupStatuses: popupView.help,});}}>고객센터</li>
            <li  data-toggle="modal" data-target="#MypageModal" onClick={() => { this.setState({popupStatuses: popupView.edit,});}}>마이페이지</li>
          </ul> */}
				</div>

			</div>

        {RenderPopup()}  
       </>

    );
  }
}

topBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export let TopBar = withStyles(styles, { withTheme: true })(topBar);
export default TopBar;
